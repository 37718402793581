import React, { Fragment } from 'react'
import { styled } from '../../../stitches/stitches.config'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import replaceLocation from '../../../utils/replaceLocation'
import LazyImageContainer from './commons/LazyImage'

import type { SliceItemType, ImageProps } from '../types'

export interface ImageSectionProps
  extends SliceItemType<'image_section', string | null> {
  primary: ImagePrimaryProps
  items: ImageItemProps
}

type ImageItemProps = any

type ImagePrimaryProps = {
  full_image: ImageProps
  full_image_link: string | null
  full_image_web_modal_text: string | null
  full_image_web_link: string | null
}

export default function ImageSection({ slice }: { slice: ImageSectionProps }) {
  const item = slice.primary
  const image = getImage(item.full_image.localFile)
  const handleClickImage = (url: string) => {
    if (url) {
      replaceLocation(url)
    }
  }
  return (
    <Fragment>
      <LazyImageContainer
        onClick={() => {
          if (!item.full_image_link && item.full_image_web_modal_text) {
            alert(item.full_image_web_modal_text)
          }
          if (item.full_image_link) {
            handleClickImage(item.full_image_link)
          }
        }}
      >
        {image && <GatsbyImage image={image} alt={item.full_image.alt || ''} />}
      </LazyImageContainer>
    </Fragment>
  )
}
