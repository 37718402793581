import React from 'react'
import { styled } from '../stitches/stitches.config'
import { vars } from '@seed-design/design-token'

const { $semantic } = vars

export const DefaultLayout = ({ children }: any) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  )
}

const Wrapper = styled('div', {
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: $semantic.color.paperDefault,
})

const Container = styled('div', {
  maxWidth: 900,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
})

export default DefaultLayout
