import React from "react";
export default function Logo() {
  return (
    <svg
      width="120"
      height="34"
      viewBox="0 0 120 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.689 6.20093C5.68421 6.20093 0 11.8851 0 18.8899C0 21.8182 1.03349 24.5167 2.69856 26.6985C2.8134 26.8708 2.98565 27.043 3.10048 27.1579C3.27273 27.3875 3.50239 27.6172 3.73206 27.8469C6.14354 30.4306 9.24402 32.3827 12.689 33.4162C16.134 32.3827 19.2345 30.4306 21.6459 27.8469C21.8756 27.6172 22.0478 27.3875 22.2775 27.1579C22.3923 26.9856 22.5646 26.8134 22.6794 26.6985C24.3445 24.5741 25.378 21.8756 25.378 18.8899C25.378 11.8851 19.6938 6.20093 12.689 6.20093ZM12.689 23.7129C9.99043 23.7129 7.80861 21.5311 7.80861 18.8325C7.80861 16.1339 9.99043 13.9521 12.689 13.9521C15.3876 13.9521 17.512 16.1339 17.512 18.8325C17.512 21.5311 15.3876 23.7129 12.689 23.7129Z"
        fill="#FF8A3D"
      />
      <path
        d="M16.823 5.91388C17.6842 5.74163 18.3158 4.99521 18.3158 4.07655C18.3158 3.04306 17.4546 2.23923 16.4785 2.23923C16.1914 2.23923 15.9043 2.29665 15.6747 2.41148C15.3302 1.03349 14.1244 0 12.689 0C11.2536 0 10.0479 1.03349 9.76078 2.41148C9.53111 2.29665 9.24403 2.23923 8.95695 2.23923C7.92346 2.23923 7.11963 3.10048 7.11963 4.07655C7.11963 4.99521 7.75121 5.74163 8.61245 5.91388C9.93303 5.45455 11.311 5.22488 12.7464 5.22488C14.1818 5.22488 15.5024 5.45455 16.823 5.91388Z"
        fill="#56C271"
      />
      <path
        d="M34.4498 19.0621H42.201C43.1771 19.0621 43.9235 18.488 43.9235 17.2822C43.9235 16.0765 43.1771 15.5023 42.201 15.5023H35.4259C35.0814 15.5023 34.8517 15.2727 34.8517 14.9856V11.9425C34.8517 11.598 35.0814 11.4258 35.4259 11.4258H42.201C43.1771 11.4258 43.9235 10.8516 43.9235 9.64587C43.9235 8.44013 43.1771 7.86597 42.201 7.86597H34.4498C31.8086 7.86597 30.5455 9.01429 30.5455 11.2535V15.732C30.5455 17.9138 31.8086 19.0621 34.4498 19.0621Z"
        fill="#FF8A3D"
      />
      <path
        d="M44.1531 20.4976H36.5167C33.0718 20.4976 30.8325 22.3923 30.8325 25.1483C30.8325 27.8468 33.0718 29.7416 36.5167 29.7416H44.1531C47.5981 29.7416 49.8947 27.8468 49.8947 25.1483C49.8947 22.3923 47.5981 20.4976 44.1531 20.4976ZM44.1531 26.1818H36.5167C35.6555 26.1818 35.1962 25.7224 35.1962 25.1483C35.1962 24.5167 35.6555 24.0574 36.5167 24.0574H44.1531C45.0143 24.0574 45.4737 24.5167 45.4737 25.1483C45.4737 25.7224 45.0143 26.1818 44.1531 26.1818Z"
        fill="#FF8A3D"
      />
      <path
        d="M50.6412 11.4832H49.4354V9.12915C49.4354 8.09565 48.5742 7.34924 47.2536 7.34924C45.9904 7.34924 45.0718 8.09565 45.0718 9.12915V18.1435C45.0718 19.177 45.9904 19.9234 47.2536 19.9234C48.5168 19.9234 49.4354 19.177 49.4354 18.1435V15.6746H50.6412C51.6172 15.6746 52.4211 14.8134 52.4211 13.5502C52.4211 12.4019 51.6172 11.4832 50.6412 11.4832Z"
        fill="#FF8A3D"
      />
      <path
        d="M72.2871 21.0718H54.5455C53.512 21.0718 52.6508 20.3828 52.6508 19.1196C52.6508 17.9713 53.512 17.2249 54.5455 17.2249H72.2871C73.3206 17.2249 74.1818 17.9713 74.1818 19.1196C74.1818 20.3828 73.378 21.0718 72.2871 21.0718ZM68.6699 14.8134V12.5168C68.6699 12.1148 68.4402 11.8852 68.0957 11.8852H55.7512C54.6603 11.8852 53.7991 11.1962 53.7991 9.93303C53.7991 8.66987 54.7177 7.92346 55.7512 7.92346H69.0144C71.6555 7.92346 72.9761 9.30145 72.9761 11.7129V14.8708C72.9761 15.9043 72.1149 16.6507 70.7943 16.6507C69.4737 16.6507 68.6699 15.8469 68.6699 14.8134ZM58.2775 23.5407V25.0909C58.2775 25.378 58.5072 25.6077 58.8517 25.6077H71.5407C72.6316 25.6077 73.4928 26.2967 73.4928 27.5598C73.4928 28.823 72.5742 29.512 71.5407 29.512H57.9905C55.3493 29.512 53.9713 28.4211 53.9713 26.0096V23.5407C53.9713 22.5072 54.8326 21.7608 56.1531 21.7608C57.3589 21.7608 58.2775 22.5646 58.2775 23.5407Z"
        fill="#FF8A3D"
      />
      <path
        d="M76.4211 25.5503V10.9091C76.4211 8.89956 77.4546 7.86607 79.5216 7.86607H85.2058C87.2727 7.86607 88.3062 8.89956 88.3062 10.9091V25.5503C88.3062 27.5599 87.2727 28.5933 85.2058 28.5933H79.5216C77.3972 28.5933 76.4211 27.5599 76.4211 25.5503ZM84.1723 24.1723V12.2871C84.1723 12 84 11.8278 83.7704 11.8278H80.957C80.7273 11.8278 80.5551 12 80.5551 12.2871V24.1149C80.5551 24.402 80.7273 24.5742 80.957 24.5742H83.7704C84 24.6316 84.1723 24.402 84.1723 24.1723ZM90.89 27.9043V9.30148C90.89 8.21057 91.8086 7.40674 93.0718 7.40674C94.335 7.40674 95.2536 8.26798 95.2536 9.30148V15.3876H96.4594C97.4354 15.3876 98.2393 16.3063 98.2393 17.512C98.2393 18.7752 97.4354 19.6938 96.4594 19.6938H95.2536V27.9618C95.2536 29.0527 94.3924 29.8565 93.0718 29.8565C91.8086 29.7991 90.89 28.9953 90.89 27.9043Z"
        fill="#FF8A3D"
      />
      <path
        d="M109.378 12.4594H110.239V9.12923C110.239 8.15315 111.043 7.52157 112.191 7.52157C113.34 7.52157 114.144 8.15315 114.144 9.12923V19.1771C114.144 20.1531 113.397 20.7847 112.191 20.7847C110.986 20.7847 110.239 20.0957 110.239 19.1197V16.1914H109.378C108.919 16.1914 108.459 15.9618 108.172 15.5598C106.967 18.201 104.9 20.3254 101.971 21.646C101.053 22.0479 100.019 21.8182 99.5024 20.8996C98.9857 19.9809 99.3302 18.89 100.134 18.4881C101.455 17.9139 102.545 17.1101 103.407 16.134H101.282C100.478 16.134 99.7321 15.6747 99.7321 14.5838C99.7321 13.6077 100.421 13.0335 101.282 13.0335H105.014C105.129 12.6316 105.244 12.2297 105.244 11.8278C105.301 11.4833 105.129 11.2536 104.785 11.2536H101.34C100.364 11.2536 99.6747 10.6795 99.6747 9.58856C99.6747 8.49765 100.364 7.86607 101.34 7.86607H105.474C108.23 7.86607 109.493 9.18664 109.091 12.1723C109.091 12.2871 109.091 12.402 109.034 12.5168C109.206 12.4594 109.263 12.4594 109.378 12.4594ZM117.129 29.3972C114.488 28.823 111.33 27.3876 109.263 25.7225C107.368 27.6173 104.842 28.9953 102.144 29.512C101.225 29.6842 100.191 29.2823 99.8469 28.134C99.5598 27.0431 100.077 26.1244 101.053 25.8374C103.981 25.0909 106.392 23.3685 107.598 21.1292C108 20.4402 108.976 19.9809 110.182 20.3254C111.388 20.7273 111.904 21.8756 111.502 22.6795V22.7369C113.225 24.1149 115.694 25.2058 118.163 25.7799C119.081 26.0096 119.656 26.9283 119.368 28.0192C119.081 29.1675 118.048 29.6268 117.129 29.3972ZM115.12 19.8086V9.01439C115.12 8.03832 115.923 7.40674 117.129 7.40674C118.335 7.40674 119.139 8.03832 119.139 9.01439V19.8086C119.139 20.7847 118.335 21.4163 117.129 21.4163C115.923 21.4163 115.12 20.7847 115.12 19.8086Z"
        fill="#FF8A3D"
      />
    </svg>
  );
}
