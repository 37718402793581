import React, { HTMLAttributes } from 'react'
import { styled } from '../../../../stitches/stitches.config'

interface DescriptionSectionProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactElement
}

export default function DescriptionSection({
  children,
  ...props
}: DescriptionSectionProps) {
  return (
    <DescriptionSectionContainer {...props}>
      {children}
    </DescriptionSectionContainer>
  )
}

const DescriptionSectionContainer = styled('div', {
  textAlign: 'center',
  padding: 20,
})
