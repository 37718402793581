import React from "react";

export default function DropdownSVG() {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.934266 0.43451C1.24669 0.12209 1.75322 0.12209 2.06564 0.43451L7.99995 6.36882L13.9343 0.43451C14.2467 0.12209 14.7532 0.12209 15.0656 0.43451C15.3781 0.746929 15.3781 1.25346 15.0656 1.56588L8.56564 8.06588C8.25322 8.3783 7.74669 8.3783 7.43427 8.06588L0.934266 1.56588C0.621846 1.25346 0.621846 0.746929 0.934266 0.43451Z"
        fill="#212124"
      />
    </svg>
  );
}
