import React from 'react'

import { ContentSection } from './containers/ContentSection'

import type { PrismicDocument } from '@prismicio/types'

interface PrismicViewerProps {
  document?: PrismicDocument<Record<string, any>, string, string>
}

function App(props: PrismicViewerProps) {
  return <ContentSection document={props.document} />
}

export default App
