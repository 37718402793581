import React, { useState, Fragment } from 'react'
import { css, styled } from '../../../stitches/stitches.config'
import { vars } from '@seed-design/design-token'

import { ImageProps, SliceItemType, TextProps } from '../types'
import DropDownSVG from '../../../assets/reactComponents/Dropdown'
import {
  smoothDownAnimation,
  smoothUpAnimation,
} from '../animations/smoothDown'
import useDarkModeState from '../../../hooks/useDarkModeState'
import PrismicRichTextComponent from './commons/PrismicRichTextComponent'

const { $scale } = vars

export interface FaqProps extends SliceItemType<'faq_section', string | null> {
  items: FaqItemProps[]
  primary: FaqPrimaryProps
}

type FaqPrimaryProps = {
  optional_image: ImageProps
  faq_description: any
  faq_background_color_light: string | null
  faq_background_color_dark: string | null
}

type FaqItemProps = {
  title: TextProps
  text: TextProps
}

export default function FaqSlice({ slice }: { slice: FaqProps }) {
  const darkmode = useDarkModeState()
  const ContainerStyle = css({
    backgroundColor:
      (!darkmode
        ? slice.primary.faq_background_color_light
        : slice.primary.faq_background_color_dark) ||
      vars.$semantic.color.paperBackground,
  })()
  return (
    <Container className={ContainerStyle}>
      <InfoContainer>
        <PrismicRichTextComponent field={slice.primary.faq_description} />
      </InfoContainer>
      <FAQContainer>
        {slice.primary.optional_image.url && (
          <LeftSection>
            <FAQImage
              src={slice.primary.optional_image.url || ''}
              alt={slice.primary.optional_image.alt || ''}
            />
          </LeftSection>
        )}
        <RightSection>
          {slice.items.map((s, idx) => {
            return (
              <FAQItemComponent key={s.title.text} data={s}></FAQItemComponent>
            )
          })}
        </RightSection>
      </FAQContainer>
    </Container>
  )
}

const FAQItemComponent: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { data: FaqItemProps }
> = ({ children, data, ...props }) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Fragment>
      <FAQItem {...props} active={open} onClick={setOpen.bind(null, !open)}>
        <FAQItemTitleWrapper>
          <p className="title"> {data.title.text} </p>
          <DropDownSVG />
        </FAQItemTitleWrapper>
        <FAQItemDescriptionWrapper className={open ? 'active' : 'inactive'}>
          <p>{data.text?.text} </p>
        </FAQItemDescriptionWrapper>
      </FAQItem>
    </Fragment>
  )
}

const Container = styled('section', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '5% 0%',
})

const InfoContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: 30,
  '& .title': {
    display: 'inline-flex',
    fontSize: '3rem',
    fontWeight: 'bold',
    color: vars.$scale.color.gray900,
    paddingBottom: '1rem',
  },
  '& .description': {
    fontSize: '1.5rem',
    color: vars.$scale.color.gray700,
  },
})

const FAQContainer = styled('section', {
  display: 'flex',
  flexDirection: 'row',
})

const LeftSection = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  marginRight: 15,
})

const RightSection = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const FAQItem = styled('div', {
  width: '100%',
  padding: '1.5rem',
  borderBottom: `1px solid ${$scale.color.gray200}`,
  cursor: 'pointer',
  transition: 'all ease 0.5s',
  color: vars.$scale.color.gray900,
  '& svg': {
    transition: 'all ease 0.5s',
  },
  '& svg path': {
    fill: $scale.color.gray900,
  },
  '& .active': {
    animation: `${smoothDownAnimation} .3s`,
    maxHeight: 100,
    overflow: 'scroll',
  },
  '& .inactive': {
    animation: `${smoothUpAnimation} .3s`,
    overflow: 'hidden',
    maxHeight: 0,
  },
  variants: {
    active: {
      true: {
        '& svg': {
          transform: 'rotate(180deg) scale(0.7)',
        },
      },
      false: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
  },
})

const FAQItemTitleWrapper = styled('div', {
  fontSize: 18,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > p': {
    fontSize: '1.5rem',
    lineHeight: 1.35,
  },
})

const FAQItemDescriptionWrapper = styled('div', {})

const FAQImage = styled('img', {
  width: '100%',
  height: '100%',
})
