export default function copyToClipBoard(text: string) {
  const tempTextArea = document.createElement('textarea')
  tempTextArea.value = text
  document.body.appendChild(tempTextArea)

  tempTextArea.select()
  document.execCommand('copy')
  document.body.removeChild(tempTextArea)
  return
}
