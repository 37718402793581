export default function GlobalTranslations(country?: CountryCode) {
  switch (country) {
    case 'kr':
      return {
        modal: '당근마켓 앱을 이용해주세요.',
        download: '앱 다운로드',
        copyButtonModal: '링크가 복사되었어요.',
        unknownErrorMessage:
          '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.',
      }

    case 'ca':
    case 'uk':
      return {
        modal: 'Please Use Karrot App.',
        download: 'App Download',
        copyButtonModal: 'The link has been copied.',
        unknownErrorMessage:
          'Oops, something went wrong. Please try it again later.',
      }
    case 'jp':
      return {
        modal: 'アプリを利用してみてください！',
        download: 'アプリをダウンロード',
        copyButtonModal: 'リンクがコピーされました。',
        unknownErrorMessage: '一時的なエラーが発生しました。',
      }
    default:
      return {
        modal: '당근마켓 앱을 이용해주세요.',
        download: '앱 다운로드',
        copyButtonModal: '링크가 복사되었어요.',
        unknownErrorMessage:
          '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.',
      }
  }
}
