interface errorMessageProps {
  title: string
  subTitle: string
  close: string
  unknownErrorMessage: string
}

export const errorMessage = (country: CountryCode): errorMessageProps => {
  switch (country) {
    case 'kr':
      return {
        title: '앗! 죄송해요.',
        subTitle:
          '해당 페이지로 접근할 수 없어요. 서버상 에러이거나 게시글이 삭제되었을 수 있어요.',
        close: '돌아가기',
        unknownErrorMessage:
          '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.',
      }
    case 'ca':
    case 'uk':
      return {
        title: 'Oops, sorry!',
        subTitle:
          "Oops, we can't find the page you're looking for. \n Make sure the address was entered correctly.",
        close: 'Go to home',
        unknownErrorMessage:
          'Oops, something went wrong. Please try it again later.',
      }
    case 'jp':
      return {
        title: '申し訳ありません。',
        subTitle:
          'お探しのページが見つかりません。\nURLが間違って入力されているか、 \nURLが変更または削除されて使用できなくなっています。 \n入力されたページのURLが間違っていないかもう一度確認してください。',
        close: 'ホームに戻る',
        unknownErrorMessage: '一時的なエラーが発生しました。',
      }
    default:
      return {
        title: '앗! 죄송해요.',
        subTitle:
          '해당 페이지로 접근할 수 없어요. 서버상 에러이거나 게시글이 삭제되었을 수 있어요.',
        close: '돌아가기',
        unknownErrorMessage:
          '알 수 없는 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.',
      }
  }
}
