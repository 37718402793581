import React from 'react'
import { styled } from '../../../../stitches/stitches.config'

import PrismicRichTextComponent from './PrismicRichTextComponent'

import type { CardCarouselSliceItemProps } from '../CardsCarouselSection'
import DescriptionSection from './DescriptionSection'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SlideItem: React.FC<
  CardCarouselSliceItemProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>
> = ({ image, card_carousel_item_description, ...props }) => {
  const imageItem = getImage(image.localFile)
  return (
    <Container {...props}>
      {image && (
        <ImageContainer>
          {imageItem && (
            <GatsbyImage
              image={imageItem}
              alt={image.alt || ''}
              style={{
                padding: '2rem',
                top: 0,
                left: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ImageContainer = styled('div', {
  width: '100%',
  height: 600,
  position: 'relative',
  '& img': {
    padding: '2rem',
  },
})

export default SlideItem
