import React, { useEffect } from "react";

const useOutBoundClick = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  {
    outboundFunction,
    inboundFunction,
  }: {
    outboundFunction: (...args: any) => void;
    inboundFunction?: (...args: any) => void;
  }
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        outboundFunction(event);
      } else {
        inboundFunction?.call(null, event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inboundFunction, outboundFunction, ref]);
};
export default useOutBoundClick;
