import React from "react";

export default function RightArrow({ className, onClick }: any) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L8.03033 7.46967C8.32322 7.76256 8.32322 8.23744 8.03033 8.53033L1.53033 15.0303C1.23744 15.3232 0.762563 15.3232 0.46967 15.0303C0.176777 14.7374 0.176777 14.2626 0.46967 13.9697L6.43934 8L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z"
        fill="#212124"
      />
    </svg>
  );
}
