import React, { Fragment } from 'react'
import { styled } from '../../../stitches/stitches.config'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import replaceLocation from '../../../utils/replaceLocation'
import LazyImageContainer from './commons/LazyImage'

import type { SliceItemType, ImageProps } from '../types'

export interface LoggingButtonSectionProps
  extends SliceItemType<'logging_button', string | null> {
  primary: LoggingButtonPrimaryProps
  items: LoggingButtonItemProps
}

type LoggingButtonItemProps = any

type LoggingButtonPrimaryProps = {
  logging_button_image_id: string | null
  logging_button_image_link: string | null
  logging_button_web_modal_text: string | null
  logging_button_web_link: string | null
  logging_button_image: ImageProps
}

export default function LoggingButtonSection({
  slice,
}: {
  slice: LoggingButtonSectionProps
}) {
  const item = slice.primary
  const image = getImage(item.logging_button_image.localFile)
  const handleClickImage = (url: string) => {
    if (url) {
      replaceLocation(url)
    }
  }
  return (
    <Fragment>
      <LazyImageContainer
        onClick={() => {
          if (
            !item.logging_button_web_link &&
            item.logging_button_web_modal_text
          ) {
            alert(item.logging_button_web_modal_text)
          }
          if (item.logging_button_web_link) {
            handleClickImage(item.logging_button_web_link)
          }
        }}
      >
        {image && (
          <GatsbyImage
            image={image}
            alt={item.logging_button_image.alt || ''}
          />
        )}
      </LazyImageContainer>
    </Fragment>
  )
}
