import { useState, useEffect } from 'react'

export default function useDarkModeState() {
  const [darkmode, setDarkMode] = useState<boolean>(
    typeof window !== 'undefined' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
  )

  useEffect(() => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)')
    const apply = () => {
      if (mq.matches) {
        setDarkMode(true)
      } else {
        setDarkMode(false)
      }
    }
    apply()
  }, [])
  return darkmode
}
