import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ErrorBoundary } from '@sentry/gatsby'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

import DaangnPrismicViewer from '../../components/WebPrismicViewer'
import useSetSeedDesign from '../../hooks/useSetSeedDesign'
import { Seo } from '../../components/Seo'
import ErrorPage from '../ErrorPage'
import WebScreenHelmet from '../../components/WebScreenHelmet/WebScreenHelmet'
import DefaultLayout from '../../layout/DefaultLayout'

const ContentPage = ({ data }: PageProps<any>) => {
  if (!data) {
    return null
  }
  useSetSeedDesign()
  const doc = data.prismicEventPage
  return (
    <main>
      <Seo />
      <ErrorBoundary fallback={<ErrorPage />}>
        <>
          <WebScreenHelmet />
          <DefaultLayout>
            {doc && <DaangnPrismicViewer document={doc as any} />}
          </DefaultLayout>
        </>
      </ErrorBoundary>
    </main>
  )
}

export const query = graphql`
  query ContentPageQuery($uid: String) {
    prismicEventPage(uid: { eq: $uid }) {
      _previewable
      prismicId
      uid
      tags
      data {
        body {
          ... on PrismicEventPageDataBodyCardsCarousel {
            id
            slice_type
            items {
              image {
                alt
                copyright
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
            primary {
              cards_carousel_background_color_dark
              cards_carousel_background_color_light
              cards_carousel_description {
                html
                raw
                richText
                text
              }
            }
          }
          ... on PrismicEventPageDataBodyEmbedContent {
            id
            slice_type
            primary {
              embeded_item {
                embed_url
                html
                title
              }
            }
          }
          ... on PrismicEventPageDataBodyFaqSection {
            id
            slice_type
            items {
              text {
                html
                richText
                text
              }
              title {
                html
                richText
                text
              }
            }
            primary {
              faq_background_color_dark
              faq_background_color_light
              faq_description {
                html
                raw
                richText
                text
              }
              optional_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
          }
          ... on PrismicEventPageDataBodyHeader {
            id
            primary {
              title {
                html
                raw
                richText
                text
              }
              date
            }
            slice_type
          }
          ... on PrismicEventPageDataBodyImageSection {
            id
            slice_type
            primary {
              full_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
              full_image_link
              full_image_web_link
              full_image_web_modal_text
            }
          }
          ... on PrismicEventPageDataBodyLoggingButton {
            id
            slice_type
            primary {
              logging_button_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
              logging_button_image_id
              logging_button_image_link
              logging_button_web_link
              logging_button_web_modal_text
            }
          }
          ... on PrismicEventPageDataBodyShareButton {
            id
            slice_type
            primary {
              share_button_image_id
              share_button_share_link
              share_button_share_text
              share_button_web_link
              share_button_web_modal_text
              share_button_image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
          }
          ... on PrismicEventPageDataBodyFloatingButton {
            id
            slice_type
            primary {
              floating_button_background_color_light
              floating_button_background_color_dark
              floating_button_border_radius
              floating_button_bottom_position
              floating_button_button_width
              floating_button_button_height
              floating_button_button_text {
                richText
              }
              floating_button_button_link
              floating_button_button_link_web
              floating_button_button_logging_id
              floating_button_web_modal_text {
                richText
              }
              floating_button_shadow_effect
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreviewResolver(ContentPage)
