import React from 'react'

import DaangnLogo from '../assets/reactComponents/Logo'
// @ts-ignore
import DaangnGlobalLogo from '../assets/svgs/logo_karrot.svg'
// @ts-ignore
import DaangnJPLogo from '../assets/svgs/karrot_logo_jp.svg'

export const LogoByCountry: React.FC<{ country?: CountryCode }> = ({
  country,
}) => {
  switch (country) {
    case 'kr':
      return <DaangnLogo />
    case 'jp':
      return <img src={DaangnJPLogo} alt="karrot" />
    case 'ca':
    case 'uk':
      return <img src={DaangnGlobalLogo} alt="karrot" />
    default:
      return <DaangnLogo />
  }
}

export default LogoByCountry
