import { useEffect } from 'react'

export default function useSetSeedDesign() {
  useEffect(() => {
    if (typeof window === 'undefined') return
    // dark Mode
    window.document.documentElement.dataset.seed = ''
    const mq = window.matchMedia('(prefers-color-scheme: dark)')

    const apply = () => {
      if (mq.matches) {
        document.documentElement.dataset.seedScaleColor = 'dark'
      } else {
        document.documentElement.dataset.seedScaleColor = 'light'
      }
    }

    apply()

    if ('addEventListener' in mq) {
      mq.addEventListener('change', apply)
    } else if ('addListener' in mq) {
      // @ts-ignore
      mq.addListener(apply)
    }
  }, [])
  return
}
