module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"daangnlandingpages","accessToken":"MC5Zc1Q4QWhBQUFDZ0FCMk5K.dFDvv70I77-9Dnrvv73vv73vv73vv73vv70g77-9QO-_ve-_ve-_ve-_vSBFUO-_vXJl77-9Ae-_vTfvv73vv71a","promptForAccessToken":true,"apiEndpoint":"https://daangnlandingpages.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0864edf5659cf23928aedd6e7a518816"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f4df9f199f1b4ae5a520ed82554762b4@o24217.ingest.sentry.io/6548766","sampleRate":1,"tracesSampleRate":0,"env":"production_web"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
