import React, { useState, useRef } from 'react'
import { vars } from '@seed-design/design-token'
import { styled } from '../../stitches/stitches.config'

import GlobalTranslations from '../../utils/translations'
import LogoByCountry from '../LogoByCountry'
import DropDownSvg from '../../assets/reactComponents/Dropdown'
import useOutBoundClick from '../../hooks/useOutBoundClick'
import getGlobalConstant from '../../utils/getGlobalConstant'
import replaceLocation from '../../utils/replaceLocation'

// style
import * as css from './WebScreenHelmet.css'

// @ts-ignore
import AppStoreLogo from '../../assets/svgs/AppStore.svg'
// @ts-ignore
import PlayStoreLogo from '../../assets/svgs/googleplay.svg'

const { $scale, $semantic } = vars

const WebScreenHelmet: React.FC = () => {
  const country = process.env.REACT_APP_COUNTRY_CODE as CountryCode
  const TranslateWords = GlobalTranslations(country)
  const globalConstants = getGlobalConstant(country as CountryCode | undefined)
  const [dropdownOpened, setDropDownOpened] = useState<boolean>(false)
  const MenuRef = useRef<HTMLDivElement>(null)

  useOutBoundClick(MenuRef, {
    outboundFunction: (e) => {
      setDropDownOpened(false)
    },
  })

  const handlePlayStoreButtonClick = () => {
    replaceLocation(globalConstants.downLoadAppScheme.Android)
  }
  const handleAppStoreButtonClick = () => {
    replaceLocation(globalConstants.downLoadAppScheme.iOS)
  }

  return (
    <div className={css.WrapperStyle}>
      <LogoImage
        onClick={() => {
          replaceLocation(globalConstants.homeUri)
        }}
      >
        <LogoByCountry country={country as CountryCode} />
      </LogoImage>
      <DropDownText
        ref={MenuRef}
        onClick={(e) => setDropDownOpened(!dropdownOpened)}
      >
        <span>{TranslateWords.download}</span>
        <DropDownButton open={dropdownOpened}>
          <DropDownSvg />
        </DropDownButton>
        {dropdownOpened && (
          <DropDownMenu>
            <DropDownItem onClick={handleAppStoreButtonClick}>
              <img src={AppStoreLogo} alt="AppStore" />
              <span className="menu-text"> App Store </span>
            </DropDownItem>
            <DropDownItem
              onClick={handlePlayStoreButtonClick}
              style={{ borderBottom: 'none' }}
            >
              <img src={PlayStoreLogo} alt="PlayStore" style={{ height: 50 }} />
              <span className="menu-text"> Play Store </span>
            </DropDownItem>
          </DropDownMenu>
        )}
      </DropDownText>
    </div>
  )
}

const LogoImage = styled('div', {
  padding: 5,
  width: '8.5rem',
  cursor: 'pointer',
  '& > svg': {
    width: '100%',
  },
})

const DropDownText = styled('div', {
  color: $scale.color.gray900,
  fontSize: '1.5rem',
  padding: 5,
  display: 'inline-flex',
  cursor: 'pointer',
  position: 'relative',
  '@bp1': {
    fontSize: 13,
  },
})

const DropDownButton = styled('div', {
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  alignSelf: 'center',
  '& > svg': {
    transform: 'scale(0.8)',
  },
  '& path': {
    stroke: $scale.color.gray900,
    fill: $scale.color.gray900,
  },
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)',
        paddingTop: 5,
        paddingRight: 5,
      },
      false: {
        paddingLeft: 5,
      },
    },
  },
})

const DropDownMenu = styled('div', {
  borderRadius: 7,
  boxShadow: '1px 0.5px 2px rgb(0 0 0 / 25%);',
  position: 'absolute',
  display: 'flex',
  top: 35,
  right: 0,
  backgroundColor: $scale.color.gray00,
  flexDirection: 'column',
  overflow: 'hidden',
  border: `1px solid ${$semantic.color.divider2}`,
})

const DropDownItem = styled('div', {
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  borderBottom: `1px solid ${$semantic.color.divider2}`,
  fontFamily: '$web',
  fontWeight: 'bold',
  fontSize: 13,
  padding: '7px 10px',
  '&:hover': {
    backgroundColor: $scale.color.grayAlpha200,
  },
  '& img': {
    maxWidth: '30px !important',
    maxHeight: '30px !important',
  },
  '& .menu-text': {
    paddingLeft: 10,
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
})

export default WebScreenHelmet
