import React, { Fragment } from 'react'
import { styled } from '../../../stitches/stitches.config'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import globalMessages from '../../../utils/translations'
import copyToClipBoard from '../../../utils/copyToClipBoard'

import type { SliceItemType, ImageProps, CountryCode } from '../types'
import LazyImageContainer from './commons/LazyImage'

export interface ShareButtonSectionProps
  extends SliceItemType<'share_button', string | null> {
  primary: ShareButtonPrimaryProps
  items: ShareButtonItemProps
}

type ShareButtonItemProps = any

type ShareButtonPrimaryProps = {
  share_button_image_id: string | null
  share_button_share_text: string | null
  share_button_share_link: string | null
  share_button_web_modal_text: string | null
  share_button_web_link: string | null
  share_button_image: ImageProps
}

export default function ShareButtonSection({
  slice,
}: {
  slice: ShareButtonSectionProps
}) {
  const item = slice.primary
  const country = process.env.REACT_APP_COUNTRY_CODE as CountryCode
  const translateWords = globalMessages(country)
  const image = getImage(item.share_button_image.localFile)
  const handleClickShareButton = (shareUrl: string | null) => {
    try {
      if (shareUrl && shareUrl !== 'null') {
        copyToClipBoard(shareUrl)
        alert(translateWords.copyButtonModal)
      }
    } catch (err) {
      alert(translateWords.unknownErrorMessage)
    }
  }
  return (
    <Fragment>
      <LazyImageContainer
        onClick={handleClickShareButton.bind(
          null,
          item.share_button_web_link || item.share_button_share_link,
        )}
      >
        {image && (
          <GatsbyImage image={image} alt={item.share_button_image.alt || ''} />
        )}
      </LazyImageContainer>
    </Fragment>
  )
}

const Image = styled('img', {
  width: '100%',
})
