export default function getGlobalConstant(country: CountryCode | undefined) {
  const homeUri = (function () {
    switch (country) {
      case "kr":
        return "https://www.daangn.com";

      case "jp":
      case "ca":
      case "uk":
        return `https://${country}.karrotmarket.com/`;
      default:
        return `https://www.daangn.com`;
    }
  })();
  const downLoadAppScheme = (function () {
    switch (country) {
      case "kr":
        return {
          Android: `https://play.google.com/store/apps/details?id=com.towneers.www`,
          iOS: `https://itunes.apple.com/kr/app/pangyojangteo/id1018769995?l=ko&ls=1&mt=8`,
        };
      case "jp":
        return {
          Android: `https://play.google.com/store/apps/details?id=com.towneers.www`,
          iOS: `https://apps.apple.com/jp/app/id1018769995`,
        };
      case "ca":
      case "uk":
        return {
          Android: `https://play.google.com/store/apps/details?id=com.towneers.www`,
          iOS: `https://apps.apple.com/app/id1018769995`,
        };
      default:
        return {
          Android: `https://play.google.com/store/apps/details?id=com.towneers.www`,
          iOS: `https://itunes.apple.com/kr/app/pangyojangteo/id1018769995?l=ko&ls=1&mt=8`,
        };
    }
  })();
  return {
    homeUri,
    downLoadAppScheme,
  };
}
